import { Component } from 'react'

export default class Refresh extends Component {
  state = {
    unregistered: false
  }
  unregisterServiceWorkers = () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let registration of registrations) {
        registration.unregister()
      }
      this.setState({ unregistered: true })
    })
  }
  componentDidMount() {
    this.unregisterServiceWorkers()
  }
  render() {
    if (this.state.unregistered) return 'Unregistration complete'
    return 'Unregistering service worker...'
  }
}
